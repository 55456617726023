import React from 'react';
import Photo from '../../components/photo';
import { photos_tr } from '../../data/photos';

const i18n = {
  description: (
    <p>
      GÜNDÜZ FOTOĞRAFLARI / GECE FOTOĞRAFLARI
    </p>
  ),
  photos: photos_tr,
  title_dark: "Karanlık",
  title_light: "Aydınlık"
};

export default (props) => <Photo i18n={i18n} {...props} />;
